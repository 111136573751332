import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader"
import Footer from "../components/footer"
import StartTrialBtn from "../components/startTrialBtn"

import * as containerStyles from "./tutorialSoftware.module.css"

import NickelledLogo from "../images/svgs/nickelled-logo-o.svg"

import headerMan from "../images/tutorial-software/header-man.svg"

import CustomerLogoEdf from "../images/svgs/customer_logo_edf.svg"
import CustomerLogoGumtree from "../images/svgs/customer_logo_gumtree.svg"
import CustomerLogoKpmg from "../images/svgs/customer_logo_kpmg.svg"
import CustomerLogoMgh from "../images/svgs/customer_logo_mgh.svg"
import CustomerLogoPb from "../images/svgs/customer_logo_pb.svg"
import CustomerLogoStatista from "../images/svgs/customer_logo_statista.svg"


export default function TutorialSoftwarePage() {
  return (
    <>
      <SEO
        title="Tutorial Software | Tutorial Creator (100% Free Trial)"
        description="Tutorial software introduction, written by the experts. Select the right tutorial creation software and try our interactive tutorial maker."
      />

      <LiveChatLoaderProvider providerKey="ii6t4rrk" provider="intercom">
        <Intercom color="#FF9C43" />
      </LiveChatLoaderProvider>
      <header className={"w-full " + containerStyles.container}>
        <div className="w-full">
          <div class="max-w-7xl mx-auto py-4 px-2 lg:px-4">
            <div class="flex flex-row flex-wrap justify-between md:flex-nowrap">
              <Link to="/">
                <NickelledLogo class="h-8 w-auto sm:h-12"></NickelledLogo>
              </Link>
            </div>
          </div>
        </div>
        <div class="max-w-7xl mx-auto py-4 px-2 lg:px-4">
          <div class="flex flex-wrap-reverse md:flex-nowrap align-middle ">
            <div class="w-full mx-1 md:w-1/2 md:mx-0 md:pr-8 py-16">
              <h1 class="font-black text-4xl leading-tight text-navy">
                Looking For Simple Tutorial Software?
              </h1>

              <p class="text-lg">
                Nickelled guided tours offer deliver high-quality, codeless
                tutorials for all users, with advanced launchers and no
                integration required.
              </p>
              <p class="text-lg">
                Sign up for a free trial today to discover more about how
                Nickelled has changed tutorial software for the better!
              </p>
              <StartTrialBtn
                label="Start free trial"
                destination="https://app.nickelled.com/account/signup"
              ></StartTrialBtn>
            </div>
            <div class="w-full mx-1 md:w-1/2 md:mx-0 flex flex-col justify-end">
              <img class="w-full" src={headerMan} />
            </div>
          </div>
        </div>
      </header>

      <div className="w-full mt-24">
        <div class="max-w-7xl mx-auto py-4 px-2 lg:px-4 flex flex-row flex-wrap md:flex-nowrap">
          <div className="w-full md:w-2/12 md:pr-2 flex flex-col justify-between mb-16">
            <div
              className="w-full rounded-md text-white p-4 text-center"
              style={{
                backgroundColor: "#2939AA",
              }}
            >
              <h3 class="font-bold text-xl mt-0 pt-0">
                Looking for simple tutorial software?
              </h3>
              <p>
                Nickelled guided tours offer deliver high-quality, codeless
                tutorials for all users, with advanced launchers and no
                integration required.
              </p>
              <StartTrialBtn
                label="Start trial"
                destination="https://app.nickelled.com/account/signup"
              ></StartTrialBtn>
            </div>
            <div
              className="w-full rounded-md text-white p-4 text-center hidden md:inline-block"
              style={{
                backgroundColor: "#2939AA",
              }}
            >
              <h3 class="font-bold text-xl mt-0 pt-0">
                Looking for simple tutorial software?
              </h3>
              <p>
                Nickelled guided tours offer deliver high-quality, codeless
                tutorials for all users, with advanced launchers and no
                integration required.
              </p>
              <StartTrialBtn
                label="Start trial"
                destination="https://app.nickelled.com/account/signup"
              ></StartTrialBtn>
            </div>
          </div>
          <div className="w-full md:w-10/12 md:pl-2">
            <h1 className="text-oOrange font-bold">
              Tutorial Software: The 2021 Guide (100% Free Trial)
            </h1>
            <h5 id="chapter-1" className="mt-16">
              Chapter 1
            </h5>
            <h2 id="tutorial-software-expert-selection-guide">
              Tutorial Software: Expert Selection Guide
            </h2>
            <p>
              Interactive tutorials are a great way to help users to learn new
              skills and master new software by allowing them to follow along
              with a step-by-step process on the screen.
            </p>
            <p>
              However, picking the right tutorial software can be a tricky
              business - there are so many out there, so which type will work
              for you?
            </p>
            <h3>What is tutorial software?</h3>
            <p>
              Tutorial software allows you to create interactive learning tools
              which help people to learn. Typically, tutorial software can
              create step-by-step &quot;guides&quot; which highlight areas of
              the screen, so that the user can follow along and understand the
              process better.
            </p>
            <h3>Different types of tutorial software</h3>
            <p>
              It&#39;s important to be aware of the limitations of your
              operating system – not all software works on all systems.
            </p>
            <p>
              Broadly speaking, you&#39;ll be looking at three different
              categories – two are native (i.e. must be installed), one runs
              from the cloud:
            </p>
            <ul className="pl-4 list-disc">
              <li>
                <strong>PC Software:</strong> Screen recording or capture tools
                that are designed to work natively in Microsoft Windows
              </li>
              <li>
                <strong>Mac Software:</strong> Screen recorders that are
                natively designed for the Mac operating system (Mac OS X)
              </li>
              <li>
                <strong>Cloud-based Tutorial Software:</strong> Solutions that
                are cloud-based and run out of the browser, making them
                (essentially) operating-system independent.
              </li>
            </ul>
            <p>On that final category, a word of advice.</p>
            <p>
              <strong>
                Cloud-based tutorial maker software runs inside a browser, which
                means it can&#39;t capture tutorials outside of the browser.
              </strong>
            </p>
            <p>
              So, for example, if you want to record a tutorial of a piece of
              software such as Oracle, which must be installed and runs natively
              on the computer, cloud-based software won&#39;t work. You&#39;ll
              have to use a native screen recorder.
            </p>
            <p>
              That said, if you want to record a tutorial of a cloud-based tool
              such as Salesforce, Workday or Dropbox, a cloud-based tutorial
              creator will work just fine for you, as all of the activity
              happens inside the browser.
            </p>
            <p>
              To find out more about our award-winning tutorial creation tool
              (and to build your tutorial in under 60 seconds), click{" "}
              <a href="https://app.nickelled.com/account/signup">here</a>.
            </p>
            <p>Otherwise, read on to find out about each of those steps…</p>
            <h5 id="chapter-2" className="mt-16">
              Chapter 2
            </h5>
            <h2 id="how-interactive-should-it-be-">
              How Interactive Should It Be?
            </h2>
            <p>
              Interactivity is something of a spectrum – ranging from users
              thumbing through a PDF file of screenshots (not very interactive!)
              to watching video tutorials (somewhat interactive) to actually
              being immersed in the app itself and being taken through the
              process live.
            </p>
            <p>
              Clearly, the learning outcomes increase the further towards the
              interactive end you proceed – would you rather learn by looking at
              pictures, or by clicking the mouse and by doing it yourself inside
              the app?
            </p>
            <p>However, there are tradeoffs in fully interactive guides.</p>
            <p>
              It&#39;s harder to control a curriculum, for example, and some
              effects won&#39;t be available (see the next section). You&#39;ll
              need to decide which format best suits the process that you are
              trying to illustrate, based on the capability and attention span
              of your audience, as well as the context that you&#39;ll be
              delivering in.
            </p>
            <p>
              This diagram showcases the increasing level of interactivity in
              popular tutorial software packages:
            </p>
            <div className="flex flex-row justify-center">
              <StaticImage
                src="../images/tutorial-software/tutorial-software-comparison.jpg"
                alt="Tutorial Software Comparison"
                className=""
              />
            </div>
            <p class="text-center italic">
              Tutorial software comparison landscape, from video tutorials to
              fully interactive software{" "}
            </p>
            <p>
              Your choices will also be determined by exactly what you&#39;re
              trying to show in your tutorial. Common things to include are:
            </p>
            <ul className="pl-4 list-disc">
              <li>Mouse movements</li>
              <li>Keyboard strokes</li>
              <li>Annotations</li>
              <li>Entries in text boxes</li>
              <li>Full screen instructions</li>
              <li>Overlaid images</li>
              <li>Overlaid videos</li>
              <li>Hyperlinked files</li>
              <li>Visual effects</li>
              <li>Voiceovers</li>
            </ul>
            <p>
              As you can see, there are a lot of bells and whistles that can be
              added to a tutorial. Here&#39;s the rub though – generally
              speaking, the more extras you add, the less interactivity
              you&#39;ll have.
            </p>
            <p>
              At the screen recorder end of the tutorial spectrum, you&#39;re
              essentially creating a video tutorial, where all the user can do
              is play, pause, fast forward and rewind. That makes it very easy
              to add effects such as voiceovers.
            </p>
            <p>
              However, an interactive tutorial allows a user to click forwards
              at their own pace, perhaps take different paths, and experience an
              application as if they&#39;re really inside it.
            </p>
            <p>
              Obviously, this is a more technologically advanced solution, but
              it means effects such as voiceovers are much harder to add,
              because they may end up being in the wrong place (or, depending on
              the path that the user chooses, not there at all).
            </p>
            <p>
              <strong>
                So think hard about the additions you want to add to your
                tutorial.
              </strong>
            </p>
            <p>
              Based on our experience, it may be that they&#39;re not as
              necessary as you think.
            </p>
            <p>
              Using a screen recorder and offering a video instead of an
              interactive guide because you feel like a voiceover is critical
              doesn&#39;t make sense if the learning outcome of a video is
              poorer with or without the voiceover. You&#39;re better off
              skipping the voiceover and offering a superior interactive
              experience.
            </p>
            <h5 id="chapter-3" className="mt-16">
              Chapter 3
            </h5>
            <h2 id="how-will-you-deliver-">How Will You Deliver?</h2>
            <p>
              Once you&#39;ve recorded your tutorial, you&#39;ll need to deliver
              it. Some packages make this easier than others - a software tool
              such as{" "}
              <a href="https://www.nickelled.com/alternatives/captivate-alternative/">
                Adobe Captivate
              </a>
              , for instance, packages up screen recordings into neat elearning
              modules that can be easily dropped into an LMS for playback.
            </p>
            <p>
              A cheaper software package (or a free one such as Quicktime for
              Mac OS X) might only output a .mov file, leaving you to worry
              about where to host it, how to ensure it&#39;s been completed, and
              how to restrict access to the right people.
            </p>
            <p>
              At the most basic level, make sure that you can effectively embed
              the output of any tutorial maker you choose, whether it&#39;s
              interactive tutorial software or tutorial video software.
            </p>
            <p>
              In 90 percent of the cases we see, organizations end up managing
              curriculums independently from the tutorials themselves, and
              almost all LMS systems support embedding videos or walkthroughs as
              part of a curriculum that they manage.
            </p>
            <p>
              <em>
                Side note: Don&#39;t just throw your tutorial videos up on
                YouTube… It&#39;s a security risk, even if you use the unlisted
                option. Use a video content management system!
              </em>
            </p>
            <p>
              Of course, you may be delivering your tutorials in a completely
              different way, and not as part of a structured curriculum.
            </p>
            <p>
              You&#39;ll need to ask any software vendor about their
              capabilities here too – interactive tutorial software often comes
              with Javascript snippets, which you can drop into an app to launch
              the tutorial right from the page itself. Or you may choose to
              offer a popup with a tutorial video for new users. Both of these
              can be considered &quot;in-app&quot; launch options, but
              they&#39;re not the only ones.
            </p>
            <p>
              Remember that every user needs to be able to play your video
              tutorials, so avoid using proprietary video formats that some
              browsers can&#39;t handle. CanIUse.com is an excellent resource
              for checking the compatibility of your website features –{" "}
              <a href="https://caniuse.com/#feat=video">here&#39;s</a> their
              page for video playback so you can check.
            </p>
            <div className="w-full p-8 bg-oOrange-light rounded-md border-oOrange border">
              <p className="font-bold text-xl italic">
                How to launch tutorials from within an app, with Nickelled CEO,
                David Batey
              </p>
              <p>
                The most useful way to deliver a user a tutorial is, of course,
                to show it to them at the point where they need it most.
                Interactive tutorial builders are great at this – using
                Javascript or other languages, it&#39;s possible to add rules
                such as:
              </p>
              <ul className="pl-4 list-disc">
                <li>
                  &quot;Show the tutorial if the user is visiting this page for
                  the first time&quot;
                </li>
                <li>
                  &quot;Show the tutorial if the user hasn&#39;t seen this
                  feature before&quot;
                </li>
                <li>
                  &quot;Show the tutorial if the user clicks on a help
                  button&quot;
                </li>
              </ul>
              <p>
                Implementing tutorials with logic like this transforms the way
                that you deliver help, even as it shifts you away from a
                formalized learning curriculum. In our books,
                &#39;on-demand&#39; help offers a far better user experience, as
                not everybody learn at the same pace, although we fully accept
                that&#39;s it&#39;s not for everybody.
              </p>
              <p>
                Even within the parameters of the rule examples above, it&#39;s
                worth considering how you want your tutorials to appear. Some
                organizations choose to launch tutorials over live pages, which
                is a nice effect for users (who can complete their work as they
                view the tutorial) but requires more work to implement and
                maintain (what happens to the tutorial when a user goes
                off-course?).
              </p>
              <p>
                Another solution is to record the tutorial and then play it back
                over the top of the site, showing the user what to do but
                returning them to the live page when they&#39;re finished or if
                they click cancel.
              </p>
              <p>
                Even videos, the most basic kind of tutorial, can be launched
                within an app, thanks to the embed functionality available with
                most video hosts (YouTube, Vimeo and Wistia all support this).
              </p>
            </div>
            <p>
              In case you&#39;re wondering how important this step is, we&#39;ll
              offer the final words here to Panopto:
            </p>
            <p>
              <em>
                A recent survey conducted by Panopto and YouGov revealed that,
                on average, employees waste 5 hours a week just waiting to get
                some bit of knowledge from a coworker who can’t be reached, or
                blindly forging ahead to try and find that knowledge elsewhere.
                For larger companies, that translates into about $3 million in
                productivity lost each year for every 1,000 employees on your
                team.
              </em>
            </p>
            <h5 id="chapter-4" className="mt-16">
              Chapter 4
            </h5>
            <h2 id="which-software-is-right-">Which Software Is Right?</h2>
            <p>
              Below, we&#39;ve built a fairly comprehensive list of the best
              tutorial creation software packages on the market. There are a
              lot, so if you don&#39;t want to get overwhelmed, we strongly
              recommend reading the guide above and figuring out what you need
              before diving in!
            </p>
            <h3 id="interactive-tutorials">Interactive Tutorials</h3>
            <p>
              These software providers offer cloud-based tutorials which can be
              embedded inside an app for a fully interactive tutorial
              experience. All offer strong analytics features, with
              customizable, rules-based launcher options.
            </p>
            <div className="w-full grid grid-cols-1 gap-x-4 md:grid-cols-2 gap-y-4 my-8">
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <strong>Nickelled</strong>
                </p>
                <p>
                  Cloud-based tutorial creator tool that records webpages and
                  can be embedded within an app.
                </p>
                <a
                  href="https://app.nickelled.com/account/signup"
                  className="font-bold underline"
                >
                  Start Trial
                </a>
              </div>
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <strong>WalkMe</strong>
                </p>
                <p>
                  <a href="https://www.walkme.com"></a>
                </p>
                <p>
                  Enterprise-grade tutorial and walkthrough tool, suited to
                  large implementations.
                </p>
                <Link
                  to="/alternatives/walkme-alternative"
                  className="font-bold underline"
                >
                  View Full Review
                </Link>
              </div>
            </div>
            <h3 id="semi-interactive-tutorials">Semi-Interactive Tutorials</h3>
            <p>
              These software providers output click-to-proceed elearning
              modules, which have some level of interactivity. They often
              support non-cloud software and can be embedded into LMS systems
              easily - all should be SCORM-compliant.
            </p>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-y-4 gap-x-4 my-8">
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <strong>Adobe Captivate</strong>
                </p>
                <p>
                  Screen capture and elearning creation tool, capable of
                  recording native applications and adding interactive effects.
                  Easy to bundle up videos into exportable elearning packages
                  (see our full review{" "}
                  <a href="https://www.nickelled.com/alternatives/captivate-alternative/">
                    here
                  </a>
                  ).
                </p>
                <Link
                  to="/alternatives/captivate-alternative/"
                  className="font-bold underline"
                >
                  View Full Review
                </Link>
              </div>
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <strong>Articulate Storyline</strong>
                </p>

                <p>
                  The other &#39;big beast&#39; of this category, with features
                  roughly comparable to those of Adobe Captivate. Lacks
                  Adobe&#39;s Creative Cloud integration, which may be useful
                  for some users. Another big drawback is that it&#39;s PC-only.
                </p>
              </div>
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <strong>Elucidat</strong>
                </p>
                <p>
                  Screen capture and elearning creation tool designed for
                  learning professionals who want to create slick exportable
                  elearning modules. Slightly easier to use than the other tools
                  in the market, and with a full analytics suite comparable to
                  cloud-based tools.
                </p>
              </div>
            </div>
            <h3 id="software-for-tutorial-videos">
              Software for Tutorial Videos
            </h3>
            <p>
              These software providers specialize in screen recording software,
              which can be used to create tutorial videos. They tend not to be
              interactive, but may be uploaded to video hosting sites such as
              YouTube or Vimeo (or hosted on an intranet).
            </p>
            <div className="w-full grid grid-cols-1 gap-x-4 md:grid-cols-3 gap-y-4 my-8">
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <a
                    href="https://www.panopto.com"
                    className="font-bold underline"
                    target="_blank"
                  >
                    <strong>PanOpto</strong>{" "}
                  </a>
                </p>
                <p>
                  Enterprise grade screen recording software, including
                  extensive hosting and video management tools. Also includes
                  streaming support.
                </p>
              </div>
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <a
                    href="https://www.techsmith.com/video-editor.html"
                    className="font-bold underline"
                    target="_blank"
                  >
                    <strong>Camtasia</strong>
                  </a>
                </p>
                <p>
                  A widely used screen recorder solution, which is
                  well-supported and regularly updated. Includes plenty of
                  post-production effects, such as text overlays, transitions
                  and other animations. Recommended for video tutorials.
                </p>
              </div>
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <a
                    href="https://support.apple.com/downloads/quicktime"
                    className="font-bold underline"
                    target="_blank"
                  >
                    <strong>Quicktime</strong>
                  </a>
                </p>
                <p>
                  Apple&#39;s native screen capture solution. Doesn&#39;t
                  include many features, but is bundled free on all Macs. A
                  strong starting point if you&#39;re trying to figure out
                  whether video-based tutorials will work for you.
                </p>
              </div>
              <div className="bg-oOrange-light border border-oOrange rounded-md p-4">
                <p className="mt-0">
                  <a
                    href="https://www.movavi.com"
                    className="font-bold underline"
                    target="_blank"
                  >
                    <strong>Movavi</strong>
                  </a>
                </p>
                <p>
                  Available for Mac and Windows and a popular screen capture
                  solution. Extensive effects library available at extra costs –
                  for most users, Camtasia may be a better solution.
                </p>
              </div>
            </div>
            <h5 id="chapter-5" className="mt-16">
              Chapter 5
            </h5>
            <h2 id="tutorial-creator-free-trial">
              Tutorial Creator Free Trial
            </h2>
            <p>
              <strong>Can Nickelled help?</strong>
            </p>
            <p>
              Customer onboarding is a learning process, and we’d love to learn
              with you. We make simple guides to onboard and convert every user,
              with zero website changes required.
            </p>
            <p>
              Our guided tours feel &quot;real&quot; – they&#39;re interactive,
              self-paced, completely immersive and yet still as easy to play as
              video tutorials.
            </p>
            <p>
              To create a Nickelled guide today, with no code installation or
              software downloads click below.
            </p>
            <a
              href="https://app.nickelled.com/account/signup"
              className="font-bold underline"
            >
              Start Trial
            </a>
            <p>
              At the end of the process, simply create a no-obligation account
              and we’ll be in touch to talk about how you can revolutionize
              client onboarding in your company.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full bg-silver">
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 class="text-3xl font-extrabold tracking-tight text-navy text-center">
            Nickelled Products Are Trusted By The Best
          </h2>
          <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-6 lg:mt-8">
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 bg-silver">
              <CustomerLogoEdf alt="EDF"></CustomerLogoEdf>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 bg-silver">
              <CustomerLogoGumtree alt="Gumtree"></CustomerLogoGumtree>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 bg-silver">
              <CustomerLogoKpmg alt="KPMG"></CustomerLogoKpmg>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4  bg-silver">
              <CustomerLogoMgh alt="McGraw Hill"></CustomerLogoMgh>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4  bg-silver">
              <CustomerLogoPb alt="Pitney Bowes"></CustomerLogoPb>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4  bg-silver">
              <CustomerLogoStatista alt="Statista"></CustomerLogoStatista>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}
