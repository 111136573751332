import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function Footer() {
  return (
    <>
      <footer class="bg-oGrey relative" aria-labelledby="footerHeading">
        <div id="footerHeading" class="sr-only">
          Footer
        </div>
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div class="">
            <div class="grid grid-cols-3 gap-8 xl:col-span-2">
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <p class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Sales
                  </p>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <Link
                        to="/product/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Product
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/pricing/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Pricing
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/request-demo/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Book a Call
                      </Link>
                    </li>

                    <li>
                      <a
                        href="https://dashboard.nickelled.com/account/login"
                        class="text-base text-gray-300 hover:text-white"
                      >
                        Sign In
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Solutions
                  </p>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <Link
                        to="/digital-adoption-platform/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Digital Adoption Platform
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/user-onboarding-software/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        User Onboarding Software
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/tutorial-software/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Interactive Tutorial Software
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/alternatives/walkme-alternative/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        WalkMe Alternative
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/alternatives/captivate-alternative/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Captivate Alternative
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="md:mt-0">
                  <p class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Resources
                  </p>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <Link
                        to="/nickelled-alternatives/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Nickelled Alternatives
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/pricing/#frequently-asked-questions"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        FAQs
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/case-studies/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Case Studies
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://help.nickelled.com/"
                        class="text-base text-gray-300 hover:text-white"
                      >
                        Help Center
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/blog/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Blog
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/saas-resources/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        SaaS Resources
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/books/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Our Books
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <p class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Company
                  </p>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <Link
                        to="/about/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        About
                      </Link>
                    </li>

                    <li>
                      <a
                        href="https://omniplexlearning.com/careers/"
                        class="text-base text-gray-300 hover:text-white"
                        target="_blank"
                      >
                        Jobs
                      </a>
                    </li>

                    <li>
                      <Link
                        to="/about/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="md:mt-0">
                  <p class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Legal
                  </p>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <Link
                        to="/terms-of-service/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Terms & Conditions
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/privacy-policy/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Privacy
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/bug-bounty/"
                        class="text-base text-gray-300 hover:text-white"
                        activeClassName="text-base text-oOrange"
                      >
                        Bug Bounty Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
            <div class="flex space-x-6 md:order-2">
              <a
                href="https://www.facebook.com/nickelledapp/"
                class="text-gray-400 hover:text-gray-300"
              >
                <span class="sr-only">Facebook</span>
                <svg
                  class="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>

              <a
                href="https://twitter.com/nickelledapp"
                class="text-gray-400 hover:text-gray-300"
              >
                <span class="sr-only">Twitter</span>
                <svg
                  class="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </div>
            <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; Nickelled by Omniplex Learning, 2022. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}
